import React, { useContext } from 'react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import styles from './InsufficientBalanceModal.module.css';
import { SUBSCRIPTION_ADDRESS,SUBSCRIPTION_AMOUNT,PERMANENT_AMOUNT } from "../../constants/constants";

const InsufficientBalanceModal = () => {
  const { state, send } = useContext(AuthContext);
  const handleSubscription = () => {
    send({ type: 'VERIFY_TRANSACTION' });
  };
  return (
    <div className={styles.modalOverlay}>
      {state.matches('waitingForSubscription') && (
        <div className={styles.modalContent}>
          <div className={styles.title}>Insufficient Balance / No Subscription detected</div>
          <div className={styles.subtitle}>
            Hold atleast <strong>{PERMANENT_AMOUNT} $YAI </strong> for permanent access.
          </div>
          <a
            href='https://www.coingecko.com/en/coins/y'
            className={`${styles.buttonWallet} ${styles.tooltipContainer}`}
            // onClick={() => open()}
          >
            {/* <img src="/images/walletIcon.svg" alt="Wallet" /> */}
            Buy YAI
            <span className={styles.tooltip}>
              Third party platform link. YAI can not be held responsible for token availability, liquidity or stability of third party platforms
            </span>
          </a>
          <div className={styles.subtitle}>
            Subscribe for 1 month or more by depositing <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the following address: {SUBSCRIPTION_ADDRESS}
          </div>
          <div className={styles.buttonWallet} onClick={handleSubscription}>
            <img src='/images/walletIcon.svg' alt='Wallet' />
            Subscribe
          </div>
        </div>
      )}
    </div>
  );
};

export default InsufficientBalanceModal;
