import React, { useContext } from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import styles from "./SubscriptionModal.module.css";
import Loader from "../Loader/Loader";
import { SUBSCRIPTION_ADDRESS,SUBSCRIPTION_AMOUNT } from "../../constants/constants";


const SubscriptionModal = () => {
  const { state, send } = useContext(AuthContext);
  return (
    <div className={styles.modalOverlay}>
      {state.matches("verifyingTransaction") && (
        <div className={styles.modalContent}>
          <div className={styles.title}>
            Scanning for Subscription TX
          </div>
          <div className={styles.subtitle}>
            To subscribe for 1 month or more, deposit{" "}
            <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the following address:{" "}
            <strong>{SUBSCRIPTION_ADDRESS}</strong>
          </div>

          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionModal;
